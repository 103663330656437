import mraky_01 from "../../assets/img/mraky_01.png";
import mraky_02 from "../../assets/img/mraky_02.png"; 
import face from "../../assets/img/face.png";
import star from "../../assets/svg/hvezda.svg";

const Study = ({ buttonClick }) => { 
    return (
        <section className="study" id="study">
            <div className="study__top">
                <div className="perex">
                    <p>Studie</p>
                    <h2>
                        Jak se generace&nbsp;Z <br />
                        staví{" "}
                        <mark>
                            k&nbsp;práci <br />
                            a&nbsp;kariéře?
                        </mark>
                    </h2>
                </div>
                <div className="img">
                    <img src={mraky_02} alt="mraky" />
                </div>
            </div>
            <div className="study__box">
                <div className="quotebox">
                    Věděli jste, že <b>platové ohodnocení je pro 93,5&nbsp;%
                    studentů nejdůležitějším faktorem</b> při hledání práce?
                    A&nbsp;co víc, mladí by si přáli vidět rozmezí platu
                    u&nbsp;každého inzerátu.
                    <img src={star} alt="zamyšlený emoji" />
                </div>
                <div className="quotebox">
                    Home office nebo práce v&nbsp;kanceláři? <b>Gen&nbsp;Z chce mít
                    volbu, ale práce z&nbsp;domova není podmínkou.</b> Proto je
                    ideální komunikovat flexibilitu a&nbsp;nabízet částečný home
                    office.
                </div>
                <div className="quotebox">
                    Mladí <b>hledají práci na pracovních portálech, ale bez
                    komunikace na sociálních sítích se neobejdete.</b> Nastupující
                    generace si zde ověřuje dojem o&nbsp;budoucím
                    zaměstnavateli.
                    <img src={face} alt="zamyšlený emoji" />
                </div>
                <img className="mraky" src={mraky_01} alt="větší mraky" />
            </div>
            <button type="button" className="blue" onClick={buttonClick}>
                Získat studii
            </button>
        </section>
    );
};

export default Study;
