import { useLenis } from '@studio-freight/react-lenis';
import logo from '../assets/svg/logo_horizontal.svg'
import { showCookieConsent } from './UI/Cookies';
const Footer = () => {
    const lenis = useLenis();
    const scroll = (e,hash) => {
        e.preventDefault();
        lenis.scrollTo(document.getElementById(hash));
    }
    const showConsent = () => {
        showCookieConsent();
    }
    return (
        <footer>
            <div className="footer">
                <div className="footer__left">
                    <img src={logo} alt="" />
                    <p>Generační vhled<br/>podložený daty.</p>
                </div>
                <div className='footer__right'>
                    <div>
                        <a href="#study" onClick={(e)=>scroll(e,'study')}>Studie</a>
                        <a href="#conference" onClick={(e)=>scroll(e,'conference')}>Konference</a>
                        <a href="#contact" onClick={(e)=>scroll(e,'contact')}>Kontakt</a>
                    </div>
                    <div>
                        <a href='./GDPR_GenSight.pdf' target='_blank'>GDPR</a>
                        <span onClick={showConsent}>Cookies</span>
                    </div>
                </div>
            </div>
            <div className="copyright">Copyright © {new Date().getFullYear()} Vysoká škola ekonomická v Praze</div>
        </footer>
    );
}
  
export default Footer 