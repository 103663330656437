

const Services = ({ buttonClick }) => {
    const sentMail = (e, mail) => {
        window.location.href = mail;
        e.preventDefault();
    }

    return (
        <section className="services" id="services">
            <div className="services__top">
                <div className="perex">
                    <p>Služby</p>
                    <h2>
                        S čím vám <mark>pomůžeme?
                        </mark>
                    </h2>
                </div>
            </div>
            <div className="services__box">
                <div className="service">
                    <div className="image"></div>
                    <div>
                        <h3>
                            Komunikace
                        </h3>
                        <span>Na jaké inzeráty odpovídají? Jaký marketing na ně působí? Co oceňují na značce?</span>
                        <p>Pomůžeme vám v následujících oblastech:</p>
                        <ul>
                            <li>Nábor generace Z</li>
                            <li>Výběr z kandidátů</li>
                            <li>Komunikační strategie</li>
                            <li>Inzerce a reklama</li>
                            <li>Data pro strategická rozhodnutí</li>
                        </ul>
                        <div className="buttons">
                            <button type="button" className="lavender" onClick={(e)=>sentMail(e, "mailto: skrr00@vse.cz?cc=zdenek.vondra@vse.cz&subject=GENSIGHT%3A%20%C5%A0kolen%C3%AD%20na%20m%C3%ADru%20-%20Komunikace&body=Dobr%C3%BD%20den%2C%20m%C3%A1m%20z%C3%A1jem%20o%20va%C5%A1e%20slu%C5%BEby.%20Aktu%C3%A1ln%C4%9B%20%C5%99e%C5%A1%C3%ADm...")}>
                                Školení na míru
                            </button>

                            <button type="button" className="transLavender" onClick={(e)=>sentMail(e, "mailto: skrr00@vse.cz?cc=zdenek.vondra@vse.cz&subject=GENSIGHT%3A%20Konzultace%20%20-%20Komunikace&body=Dobr%C3%BD%20den%2C%20m%C3%A1m%20z%C3%A1jem%20o%20va%C5%A1e%20slu%C5%BEby.%20Aktu%C3%A1ln%C4%9B%20%C5%99e%C5%A1%C3%ADm...")}>
                                Konzultace
                            </button> 

                        </div>
                    </div>
                </div>
                <div className="service">
                    <div className="image"></div>
                    <div>
                        <h3>
                            Motivace
                        </h3>
                        <span>Jak si získat jejich důvěru a otevřít jejich maximální potenciál?</span>
                        <p>Pomůžeme vám v následujících oblastech:</p>
                        <ul>
                            <li>Relevantní benefity</li>
                            <li>Kvalitní onboarding</li>
                            <li>Platová politika</li>
                            <li>Firemní kultura</li>
                            <li>Spolupráce</li>
                            <li>Vzájemné porozumění</li>
                            <li>Důvěra</li>
                        </ul>
                        <div className="buttons">
                            <button type="button" className="blue" onClick={(e)=>sentMail(e, "mailto: skrr00@vse.cz?cc=zdenek.vondra@vse.cz&subject=GENSIGHT%3A%20%C5%A0kolen%C3%AD%20na%20m%C3%ADru%20-%20Motivace&body=Dobr%C3%BD%20den%2C%20m%C3%A1m%20z%C3%A1jem%20o%20va%C5%A1e%20slu%C5%BEby.%20Aktu%C3%A1ln%C4%9B%20%C5%99e%C5%A1%C3%ADm...")}>
                                Školení na míru
                            </button>

                            <button type="button" className="transBlue" onClick={(e)=>sentMail(e, "mailto: skrr00@vse.cz?cc=zdenek.vondra@vse.cz&subject=GENSIGHT%3A%20Konzultace%20%20-%20Motivace&body=Dobr%C3%BD%20den%2C%20m%C3%A1m%20z%C3%A1jem%20o%20va%C5%A1e%20slu%C5%BEby.%20Aktu%C3%A1ln%C4%9B%20%C5%99e%C5%A1%C3%ADm...")}>
                                Konzultace
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Services;
