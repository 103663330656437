import Footer from "./components/Footer";
import Header from "./components/Header/Header"
import Navigation from "./components/Header/Navigation"
import Overlay from "./components/Header/Overlay"
import Intro from "./components/Sections/Intro";
import { ReactLenis, useLenis} from '@studio-freight/react-lenis';
import Study from "./components/Sections/Study";
import Conference from "./components/Sections/Conference";
import Contact from "./components/Sections/Contact";
import logo from "./assets/svg/logo_horizontal.svg"
import { useEffect, useState } from "react";
import FormModal from "./components/FormModal";
import HeaderIntLink from "./components/Header/HeaderIntLink";
import {initCookieConsent} from "./components/UI/Cookies";
import { ToastProvider } from "./components/UI/useToast";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import HashScroll from "./components/UI/HashScroll";
import Services from "./components/Sections/Services";

gsap.registerPlugin(ScrollTrigger);

const App = () => {
  const [studyModal, setStudyModal] = useState(false);
  const [conferenceModal, setConferenceModal] = useState(false);
  const [headerHide, setHeaderHide] = useState(false);
  const lenis = useLenis(({direction})=>{
    ScrollTrigger.update();
    if (direction === -1) {setHeaderHide(false);}
    if (direction === 1) {setHeaderHide(true);}
  });
  const scroll = (hash) => {
    lenis.scrollTo(document.getElementById(hash));
  }
  useEffect(()=>{
      initCookieConsent();
      gsap.timeline({
        scrollTrigger: {
          trigger: "#conference",
          start: 'top',
          end: 'bottom 25%',
          toggleClass: { targets: '#logo, .nav .transBlack, .hamburger', className: "invert" }
        }
      })
  })

  return (
    <ReactLenis root>
      <ToastProvider>
        <Header fixed stopScroll hamburger='1px' className={headerHide?'hide':''}>
          <img id='logo' src={logo} alt="gensight logo" />
          <Navigation moveRight hideOnMobile={414}>
            <button type="button" className="transBlack" onClick={()=>setStudyModal(true)}>získat studii</button>
          </Navigation>
          <Overlay width='50vw' justify='space-evenly'>
            <HeaderIntLink href='#intro' onClick={()=>scroll("intro")}>About</HeaderIntLink>
            <HeaderIntLink href="#study" onClick={()=>scroll("study")}>Studie</HeaderIntLink>
            <HeaderIntLink href="#conference" onClick={()=>scroll("conference")}>Konference</HeaderIntLink>
            <HeaderIntLink href="#contact" onClick={()=>scroll("contact")}>Kontakt</HeaderIntLink>
            <button type="button" className="transBlack" onClick={()=>setStudyModal(true)}>získat studii</button>
          </Overlay>
        </Header>
        
        <main>
          <Intro />
          <Study buttonClick={()=>setStudyModal(true)} />
          <Services/>
          <Conference buttonClick={()=>setConferenceModal(true)} />
          <Contact />
        </main>
        <FormModal state={[studyModal, setStudyModal]} type='study' />
        <FormModal state={[conferenceModal, setConferenceModal]} type='register' />
        <Footer/>
        <HashScroll />
      </ToastProvider>

      <span id="statusUpdate">f54EcQEMDHrvSR2zPvEN7krfbabRdQULyzeNisw2jHtBoC7Dam6cFQyWPbp2nBjcJJPgwvcQAnNFu6tfXrJkA6Afx25uvBWNM3PgneCGU3dwgxTjtWX2MhJYAy5dWn6E</span>
    </ReactLenis>
  );
}

export default App;
