import Form from "../Form/Form";
import Input from "../Form/Input";
import Checkbox from "../Form/Checkbox";
import contactpic from "../../assets/svg/contactpic.svg";
import vse from "../../assets/svg/fis.svg";
import strat from "../../assets/svg/strateggo.svg";
import csob from "../../assets/svg/csob.svg";
import { APIURL } from "../../index";
import axios from "axios";
import { useToast } from "../UI/useToast";
import { useEffect, useState } from "react";

const Contact = () => {
    const { addToast } = useToast();
    const [formData, setFormData] = useState({});
    const handleSubmit = (data) => {
        var dataForm = formData?.inputs.map((d, i) => ({ id: d.id, value: data[d.name] }));

        axios
            .post(APIURL + "/newAnswer", {
                data: dataForm, name: "contact",
                language: "cz"
            })
            .then((response) => {
                addToast({
                    type: "success",
                    message: "Váš dotaz byl odeslán.",
                });
            })
            .catch((err) => {
                addToast({
                    type: "error",
                    message:
                        "Nastala se chyba, kontaktujte nás prosím na admin@agenturagen.cz.",
                });
            });
    };
    const getForm = async () => {
        await axios
            .post(APIURL + "/form", { name: "contact", language: "cz" })
            .then((response) => {
                setFormData(response.data.body);
            });
    };
    useEffect(() => {
        getForm();
    }, []);
    return (
        <>
            <section className="contact" id="contact">
                <div className="contact__left">
                    <div className="perex">
                        <p>Kontakt</p>
                        <h2>
                            Udělejte první
                            <br /> krok{" "}
                            <mark>a inovujte pro Generaci&nbsp;Z</mark>
                        </h2>
                    </div>
                    <img src={contactpic} alt="Contact img" />
                </div>
                <div className="contact__right form">
                    <Form onSubmit={handleSubmit}>
                        {formData?.inputs?.map(
                            (input, index) =>
                                (input.type == "input" ||
                                    input.type == "textarea") && (
                                    <>
                                        <Input
                                            id={input.id}
                                            key={index}
                                            label={input.label}
                                            name={input.name}
                                            placeholder={input.placeholder}
                                            className={
                                                input.type == "textarea" ||
                                                    input.name == "company"
                                                    ? ""
                                                    : "half"
                                            }
                                            required={
                                                input.required ? true : false
                                            }
                                            type={input.input_type}
                                        />
                                    </>
                                )
                        )}
                        <Checkbox name="GDPR" required>
                            Souhlasím s <a href='../GDPR_GenSight.pdf'>GDPR</a>
                        </Checkbox>
                        <button type="submit">Odeslat dotaz</button>
                    </Form>
                </div>
            </section>
            <section className="partners">
                <img src={vse} alt="Logo - VŠE" />
                <img src={csob} alt="Logo - ČSOB" />
                <img src={strat} alt="Logo - Strateggo" />
            </section>
        </>
    );
};

export default Contact;
